<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Gallery Page" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title *"
                  v-model="title"
                  :error="!!errors.gallery_title"
                  :error-messages="errors.gallery_title"
                  @input="delete errors.gallery_title"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteImage(imageId, 'gallery_image') ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.gallery_image"
                    v-model="image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('gallery_image', 'gallery_image', image[0])">Завантажити</va-button>
                  <div v-if="errors.gallery_image" style="color: red;">{{ errors.gallery_image[0] }}</div>
                </va-card>
                <va-input
                  label="SEO Title"
                  v-model="seoTitle"
                  :error="!!errors.gallery_seo_title"
                  :error-messages="errors.gallery_seo_title"
                  @input="delete errors.gallery_seo_title"
                />
                <va-input
                  label="SEO Description"
                  type="textarea"
                  v-model="seoDescription"
                  :error="!!errors.gallery_seo_description"
                  :error-messages="errors.gallery_seo_description"
                  @input="delete errors.blog_gallery_description"
                />
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                  :error="!!errors.gallery_seo_ogtitle"
                  :error-messages="errors.gallery_seo_ogtitle"
                  @input="delete errors.gallery_seo_ogtitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                  :error="!!errors.gallery_seo_ogdescription"
                  :error-messages="errors.gallery_seo_ogdescription"
                  @input="delete errors.gallery_seo_ogdescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(imageId, 'gallery_seo_ogimage') ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.gallery_seo_ogimage"
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('gallery_seo_ogimage', 'gallery_seo_ogimage', seoOpenGraphImage[0])">Завантажити</va-button>
                  <div v-if="errors.gallery_seo_ogimage" style="color: red;">{{ errors.gallery_seo_ogimage[0] }}</div>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      title: '',
      image: [],
      imageId: '',
      imageExisting: '',

      seoTitle: '',
      seoDescription: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        gallery_title: this.title,

        gallery_seo_title: this.seoTitle,
        gallery_seo_description: this.seoDescription,
        gallery_seo_ogtitle: this.seoOpenGraphTitle,
        gallery_seo_ogdescription: this.seoOpenGraphDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'gallery') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/upload/8?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/8/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/8?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/validate/8?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/8?lang=${this.locale}`)
        .then(response => {
          this.title = response.data.gallery_title
          this.imageExisting = response.data.gallery_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.gallery_image : null
          this.imageId = response.data.gallery_image_id

          this.seoTitle = response.data.gallery_seo_title
          this.seoDescription = response.data.gallery_seo_description
          this.seoOpenGraphTitle = response.data.gallery_seo_ogtitle
          this.seoOpenGraphDescription = response.data.gallery_seo_ogdescription
          this.seoOpenGraphImageExisting = response.data.gallery_seo_ogimage ? process.env.VUE_APP_AGRO_ADMIN + response.data.gallery_seo_ogimage : null
          this.seoOpenGraphImageId = response.data.gallery_seo_ogimage_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
